/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import React, { PropsWithChildren } from "react"
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import Breadcrumbs, { BreadcrumbItem } from './Breadcrumbs'

interface Props {
  breadcrumbs?: BreadcrumbItem[],
  styles?: SerializedStyles
}

const Page: React.FC<PropsWithChildren<Props>> = props => {

  const styles = css({
    paddingLeft: tokens.spacing.m,
    paddingRight: tokens.spacing.m,
    paddingTop: props.breadcrumbs ? tokens.spacing.xxs : tokens.spacing.m,
    paddingBottom: tokens.spacing.m,
    boxSizing: 'border-box',
    margin: '0 auto',
    width: '100%',
    maxWidth: '1600px',
    '@media (min-width: 992px)': {
      paddingLeft: tokens.spacing.xxl,
      paddingRight: tokens.spacing.xxl,
      paddingTop: props.breadcrumbs ? tokens.spacing.xs : tokens.spacing.xxl,
      paddingBottom: tokens.spacing.xxl,
    },
    '@media (max-width: 600px)': {
      paddingLeft: tokens.spacing.xxs,
      paddingRight: tokens.spacing.xxs
    }
  });
  
  return (
    <div css={[styles, props.styles]}>
      { props.breadcrumbs && <Breadcrumbs items={props.breadcrumbs} />}
      {props.children}
    </div>
    
    
  );
}

export default Page;