/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, IconButton, Paper } from '@mui/material';
import log from "loglevel";
import { Button as ButtonSuomiFi, Heading, IconInfo, Modal, ModalContent, ModalFooter, ModalTitle, suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import Card from '../../common/Card';
import { useAppStateContext } from '../../../state/AppStateContext';
import { PageHeader, PageHeadingWrapper } from '../../common/CommonStyles';
import { MiniSuomiAppInfo } from '../../../models/AppModels';
import { info } from 'console';
import Markdown from 'react-markdown';

const MiniSuomiAppList: React.FC = () => {
  const logger = log.getLogger(MiniSuomiAppList.name);
  const { t, i18n } = useTranslation();
  const appContext = useAppStateContext();

  const [ visible, setVisible ] = useState<boolean>(false);
  const [ title, setTitle ] = useState<string>("");
  const [ content, setContent ] = useState<string>("");

  // logger.debug("MiniSuomiAppInfos", appContext.miniSuomiAppInfos);
  // logger.debug("language", i18n.language);

  const showInfo = (info: MiniSuomiAppInfo) => {
    setTitle(info.heading);
    setContent(info.popup || info.description);
    setVisible(true);
  }

  return (
    <>
      <PageHeader style={{marginTop: tokens.spacing.m, marginBottom: tokens.spacing.s}}>
        <PageHeadingWrapper>
          <Heading variant="h1" smallScreen>
            {t("app.components.MiniSuomiAppList.title")}
          </Heading>
        </PageHeadingWrapper>
      </PageHeader>
      <Grid container spacing={tokens.spacing.xs}>
        {appContext.miniSuomiAppInfos[i18n.language]?.map((info, index) => 
          <Grid item key={`appinfo-${index}`} xs={12} sm={6} md={4} lg={3}>
            <Box component={Paper} display="inline-flex" flexDirection="column" height= "100%" width= "100%">
              <Box display="flex" padding={tokens.spacing.s}>
                <PageHeadingWrapper>
                  {!info.headingImage &&
                    <Box display="flex" alignItems="center">
                      { info.appIcon &&
                        <Box paddingRight={tokens.spacing.xxs}>
                          <img src={info.appIcon} alt='Logo' style={{maxHeight: "4rem", maxWidth: "100%"}}/>
                        </Box>
                      }
                      <Box>
                        <Heading variant="h2" smallScreen color="brandBase" style={{padding: `${tokens.spacing.xs} 0`}}>
                          {info.heading}
                        </Heading>
                      </Box>
                    </Box>
                  }
                  {info.headingImage && <img src={info.headingImage} alt='Logo' style={{maxHeight: "4rem", maxWidth: "100%"}}/>}
                </PageHeadingWrapper>
              </Box>
              <Box display="flex" padding={tokens.spacing.s} flexGrow={1}>
                {info.description}
              </Box>
              <Box display="flex" padding={tokens.spacing.s} justifyContent="space-between">
                <IconButton><IconInfo onClick={ () => showInfo(info) } /></IconButton>
                {/* <ButtonSuomiFi icon={<IconInfo/>} aria-label='info'></ButtonSuomiFi> */}
                {/* <Button color="primary">
                  {t("app.components.MiniSuomiAppList.moreInfoLink")}
                </Button> */}
                { info.appLink &&
                  <Button color="primary" href={info.appLink} target="_blank" sx={{textAlign: "right"}}>
                    {t("app.components.MiniSuomiAppList.linkToTheApp")}
                  </Button>
                }
             
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>

      {/* Info modal popup */}
      <Modal
        appElementId="root"
        visible={visible}
        onEscKeyDown={() => setVisible(false)}
      >
        <ModalContent>
          <ModalTitle>{ title }</ModalTitle>
          <Markdown>
           { content }
          </Markdown>
        </ModalContent>
        <ModalFooter>
          <Button
            arial-label="Accept changes and close modal dialog"
            onClick={() => setVisible(false)}
          >
            { t("app.actions.close.title") }
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default MiniSuomiAppList;

{/* <PageHeader style={{
  padding: tokens.spacing.s, 
  backgroundImage: 'url("resources/images/yd-header.png"),linear-gradient(90deg,#18073a,#164194)', 
  backgroundPosition: "center",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat"
}}></PageHeader> */}