/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import AppContextProvider, { AppStateContext } from './state/AppStateContext';
import Header from './components/common/Header';
import GlobalStyles from './components/GlobalStyles';
import FrontPage from './components/pages/frontpage/FrontPage';
import { ContentContainer, HeaderBackground, TopNavigationBackground } from './components/common/CommonStyles';
import NavBar from './components/common/NavBar';
import { ThemeProvider, createTheme } from '@mui/material';
import Footer from './components/common/Footer';


const styles = {
  contentContainer: css({
    padding: tokens.spacing.m,
    '@media (min-width: 992px)': {
      padding: tokens.spacing.xxl
    }
  })
}

const muiTheme = createTheme({
  palette: {
    primary: {
      main: tokens.colors.brandBase
    }
  }
});

const App: React.FC = () => {
  return (
    <Suspense fallback="loading">
      <AppContextProvider>
        <GlobalStyles />
        <ThemeProvider theme={muiTheme}>
          <Router>
            
            <HeaderBackground>
              <ContentContainer>
                <Header/>
              </ContentContainer>
            </HeaderBackground>
            <TopNavigationBackground>
              <ContentContainer>
                <NavBar/>
              </ContentContainer>
            </TopNavigationBackground>
            
            <AppStateContext.Consumer>
              {state => 
                <Routes>
                  { RoutePaths.map((it, i) => 
                    <Route key={`route-${i}`} path={it.to} element={it.element}/>
                  )}
                  <Route path="/" element={<FrontPage/>}/>
                  <Route path="*" element={<Navigate to={"/"}/>}/>
                </Routes>
              }
            </AppStateContext.Consumer>
            
            <Footer></Footer>
            
          </Router>
        </ThemeProvider>
      </AppContextProvider>
    </Suspense>
  )
}

export interface RoutePathProps {
  to: string;
  label: string;
  element: React.ReactNode | null;
  visibleInNav: boolean;
}

export const RoutePaths: RoutePathProps[] = [
  { to: "/", label: "frontpage", element: <FrontPage/>, visibleInNav: true},
];

export default App
