import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import Page from '../../common/Page';
import Card from '../../common/Card';
import MiniSuomiAppList from './MiniSuomiAppsList';

const InfoParagraph = styled.p`
  margin-top: ${tokens.spacing.s};
  margin-bottom: ${tokens.spacing.xxs};
`

const FrontPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  return (
    <Page>
      <Card title={t("app.frontpage.heading")}>
        <InfoParagraph>{t("app.frontpage.paragraph1")}</InfoParagraph>
        <InfoParagraph>
          <Trans
            i18nKey="app.frontpage.paragraph2"
            components={{
              link1: i18n.language === "en" ? <a href="https://www.yrityksendigitalous.fi/en/" target="_blank">Real time economy</a> : <a href="https://www.yrityksendigitalous.fi/" target="_blank">Yrityksen digitalous</a>
            }}
          />
        </InfoParagraph>
        <InfoParagraph>
          <Trans
            i18nKey="app.frontpage.paragraph3"
            components={{
              link1: <a href="https://wiki.dvv.fi/display/TYPOC/Testiaineistopalvelu" target="_blank">https://wiki.dvv.fi/display/TYPOC/Testiaineistopalvelu</a>
            }}
          />
        </InfoParagraph>
      </Card>
      <MiniSuomiAppList/>
    </Page>
  );
}

export default FrontPage;