import React, { useState } from 'react';
import { Link, useLocation, useMatch } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { NavBarContainer, NavBarHeader, NavBarHeaderContent, NavBarMenu, NavListItem, SelectedNavItemWrapper } from './NavBarStyles';
import { RoutePathProps, RoutePaths } from '../../App';
import { matchPath } from 'react-router';
import { useAppStateContext } from '../../state/AppStateContext';

const NavItem: React.FC<{to: string, label: string, onClick: () => void}> = ({ to, label, onClick }) => {
  let match = useMatch(to);
  return (
    <Link to={to} 
    className={match ? "match" : ""}
    onClick={onClick}>
      {label}
    </Link>
  );
};

const getPathLabelKey = (route: RoutePathProps, pathname: string) => {
  const match = matchPath(route.to, pathname);
  return match ? route.label : null;
}

const SelectedNavItemLabel: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  let location = useLocation();
  if (location.pathname === "/login") {
    return <span>{t(`app.navigation.login`)}</span>;
  }
  const paths = RoutePaths.map(it => getPathLabelKey(it, location.pathname)).filter(it => it !== null);
  return paths.length > 0 ? <span>{t(`app.navigation.${paths[0]}`)}</span> : <span></span>
}

const NavBar: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const appContext = useAppStateContext();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const collapseMenu = () => {
    setIsExpanded(old => false);
  }
  
  return (
    <NavBarContainer>
      <NavBarHeader>
        <NavBarHeaderContent>
          <SelectedNavItemWrapper>
            <SelectedNavItemLabel/>
          </SelectedNavItemWrapper>
        </NavBarHeaderContent>
        <IconButton title='Expand navigation' onClick={() => setIsExpanded(old => !old)}>
          <MenuIcon/>
        </IconButton>
      </NavBarHeader>
      <NavBarMenu className={isExpanded ? "": "menu-hidden"}>
        <ul>
          {appContext.isLoadingApp ? 
            <NavListItem key={`navlistitem-login`}>
              <NavItem to={"/login"} label={t(`app.navigation.login`)} onClick={collapseMenu} />
            </NavListItem> :
            RoutePaths.filter(it => it.visibleInNav).map((it, i) => 
              <NavListItem key={`navlistitem-${i}`}>
                <NavItem to={it.to} label={t(`app.navigation.${it.label}`)} onClick={collapseMenu} />
              </NavListItem>
            )
          }
        </ul>
      </NavBarMenu>
    </NavBarContainer>
  )
}

export default NavBar;